
import {Actions} from './types';



const AppReducer = (state, action) => {
    switch(action.type){
        case Actions.SetUser:
            return {...state , User: action.payload.state}
        case Actions.SetCallInfo:
            return {...state,
                    ServiceCalls: state.ServiceCalls.length !== 0 ? state.ServiceCalls.map(call =>{
                        if(call.DOCNO === action.payload.id) return {...action.payload.state, CallInfo: action.payload.state.CallInfo || call.CallInfo };
                        return call;
                    }) : [action.payload.state]
                }
        case Actions.SetServiceCalls:
            return {...state , 
                ServiceCalls: action.payload.state.map(call => {
                    const foundCall = state.ServiceCalls.find(c => c.DOCNO === call.DOCNO);
                    if(!foundCall) return call;
                    return {...call, CallInfo: foundCall.CallInfo}
                })
            }
        case Actions.SetTopics:
            return {...state , Topics: action.payload.state}
        case Actions.SetTypes:
            return {...state , Types: action.payload.state}
        case Actions.SetWhitelistFiles:
            return {...state , WhitelistFiles: action.payload.state}
        case Actions.AddServiceCall:
            return{...state, ServiceCalls: [action.payload.state, ...state.ServiceCalls]}
        case Actions.AddServiceCalls:
            return{...state, ServiceCalls: [ ...state.ServiceCalls, ...action.payload.state]}
        default:
            return state;
    }
}


export default AppReducer;