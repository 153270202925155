import React, {useEffect,useContext, useCallback} from 'react';
import { Header } from 'components/Header';
import { Home, Call, Login, ForgotPassword } from 'components/Routes';
import { Footer } from 'components/Footer';
import {Switch, Route, useHistory} from 'react-router-dom';
import { AppContext } from 'context';
import {Actions} from 'context/reducer/types'
import Http from 'service/http';
import './App.css';
import { Popup } from 'components/Popup';
import { useState } from 'react';

function App() {

  const {state, dispatch} = useContext(AppContext);
  const history = useHistory();
  const [isClosed, setIsClosed] = useState(true);
  const [openDays, setOpendays] = useState([]);
  const today = new Date();

  const GetCustName = useCallback(async()=> {
    return await Http.GetCustName();
  } , [])

  useEffect(() => {
    const sessionId = sessionStorage.getItem('sessionId');
    if(sessionId){
      if(!state.User){
         GetCustName().then(userData => {
           dispatch({type: Actions.SetUser, payload:{state:{userName: userData[0].NAME, userData} } });
           if(history.location.pathname === "/") history.replace('/home');
          })
      }
    }
    else{
      if(history.location.pathname !== "/forgotpassword" && history.location.pathname !== "/"){ 
        history.replace('/')
      };
    }
    // eslint-disable-next-line
  }, [GetCustName, dispatch])

  const openTimes = [
    {from: '08:30', to: '17:30', displayName: 'ראשון'},
    {from: '08:30', to: '17:30', displayName: 'שני'},
    {from: '08:30', to: '17:30', displayName: 'שלישי'},
    {from: '08:30', to: '17:30', displayName: 'רביעי'},
    {from: '08:30', to: '17:30', displayName: 'חמישי'},
    {from: '08:30', to: '13:00', displayName: 'שישי'}
  ]
  useEffect(() => {
    
    const Hour = today.getHours();
    const Minutes = today.getMinutes();
    const todayOpenTimes = openTimes[today.getDay()];
    
  //   const openTimeList = openTimes.reduce(function(preVal, curVal) {
  //     const dayIndex = openTimes.map(n=>n.displayName).indexOf(curVal.displayName);
  //     var last = preVal[preVal.length - 1];
  //     if (last && last.to == curVal.to && last.from == curVal.from) {
  //         last.endDay = curVal.displayName;
  //         last.daysIndex.push(dayIndex)
  //     } else {
  //         preVal.push({...curVal,daysIndex: [dayIndex] });
  //     }
  //     return preVal;
  // }, []);
  
    const isclosed = ( Hour > parseInt(todayOpenTimes.to.split(':')[0]) || (Hour === parseInt(todayOpenTimes.to.split(':')[0]) && Minutes > parseInt(todayOpenTimes.to.split(':')[1]) )) ||
                    ( Hour < parseInt(todayOpenTimes.from.split(':')[0]) || (Hour === parseInt(todayOpenTimes.from.split(':')[0]) && Minutes < parseInt(todayOpenTimes.from.split(':')[1])))

    setIsClosed(isclosed);
  }, [])


  return (
    <div className="App">
      <Header/>
      <div className='Routes'>
        <Popup onChange={(closed) => setIsClosed(closed)} title='' subtitle='' isOpen={isClosed}>
          <div className='working_hours_container'>
          לקוחות יקרים,<br/>
          מוקד השירות אינו זמין בשעות אלה.<br/>
          שעות פעילות המוקד הן:<br/>
          בימים א'-ה' – 8:30-17:30<br/>
          ערבי חג – 8:30-14:00. <br/>
          קריאתכם התקבלה ותענה בשעות הפעילות.<br/>
          במידה והתקלה היא תקלה משביתה יש להתקשר ל- 03-6438222.<br/>
          תודה.<br/>

          </div>
          {/* <table className='working_hours'>
            <tr>
              <th>שעות פעילות</th>
            </tr>
            {openDays.map(m => 
            <tr className={`${m.daysIndex.includes(today.getDay()) ? 'current' : ''}`}>
              <td>
              {m.displayName} {m.endDay ? '-'+m.endDay : ''}
              </td>
              <td>
              {m.from}-{m.to}
              </td>
               
            </tr>
            )} 
          </table>*/}
        </Popup>
        <Switch>
          <Route path='/' exact>
            <Login/>
          </Route>

          <Route path='/call/:id' exact>
            <Call/>
          </Route>

          <Route path='/home' >
            <Home/>
          </Route>

          <Route path='/forgotpassword' >
            <ForgotPassword/>
          </Route>

        </Switch>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
