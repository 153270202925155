import React, {useContext, useState} from 'react'
import {AppContext} from 'context';
import {useHistory, useRouteMatch } from 'react-router-dom';
import {useScreenSize, MOBILE_SIZE} from 'service/utils';
import classNames from 'classnames';
import './style.scss';


export const Header = () => {

    const history = useHistory();
    const {state, Logout} = useContext(AppContext);
    const match = useRouteMatch("/call/:id");
    const screen = useScreenSize();
    const [isUserNameShowed, setIsUserNameShowed] = useState(false)

    const handleLogout = () => {
        Logout().then(() => history.replace('/'));   
    }

    const goToHome = () => {
        if(state.User){
            history.push('/home')
        }
    }

    const usernameClasses = classNames('username', {open: isUserNameShowed})
    return (
        <div className='Header'>
            <div className='container'>
                
                {state.User && (match ? 
                <div className='back' onClick={()=> history.push('/home')}></div> 
                : 
                screen?.width > MOBILE_SIZE ? <button className='button' onClick={handleLogout}>יציאה מהמערכת</button> : <div onClick={handleLogout} className='icon_logout'/> 
                )}
                <div className='logo' onClick={goToHome}></div>
                {state.User ? 
                 screen?.width > MOBILE_SIZE ? <div>{state.User.userName}</div> : 
                 <div className='mobile_avatar_container'>
                     <div className='icon_avatar' onClick={()=>setIsUserNameShowed(!isUserNameShowed)}/>
                     <div className={usernameClasses}>{state.User.userName}</div>
                 </div> 
                : null
            }
            </div>
        </div>
    )
}
