import React, {useReducer, useCallback, useEffect} from 'react';
import Reducer from './reducer';
import {Actions} from 'context/reducer/types';
import http from 'service/http'


export const AppContext = React.createContext();


const initialState = {
    User: null,
    ServiceCalls: [],
    Topics: [],
    Types: [],
    WhitelistFiles: []

}
export const Provider = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState)

    const GetCallInfo = useCallback(async(id) => {
        try {
            const foundCall = state.ServiceCalls.find(call => call.DOCNO === id);
            if(foundCall) return foundCall;
             
            const callinfo = await http.GetCallInfoById(id);
            dispatch({type: Actions.SetCallInfo, payload: {id, state: callinfo} });    
            return callinfo;
        } catch (error) {
            console.error(error);
        }
        
        // eslint-disable-next-line
        },[state.ServiceCalls]);
        

        useEffect(() => {

            http.GetAllCallTypes()
            .then(types => dispatch({type: Actions.SetTypes, payload: {state: types}}))
            .catch(err => console.error(err));


            http.GetAllTopics()
            .then(topics => dispatch({type: Actions.SetTopics, payload: {state: topics}}))
            .catch(err => console.error(err));

            http.GetUploadExtWhiteList()
            .then(data => {
                const whitelist = data.value.map(ext => `.${ext.FILEEXT}`);
                dispatch({type: Actions.SetWhitelistFiles, payload: {state: whitelist}})
            })
            .catch(err => console.error(err));


    }, [dispatch])


    const Logout = async() => {
        sessionStorage.removeItem('sessionId');
        dispatch({type: Actions.SetUser, payload:{state: null} });
        dispatch({type: Actions.SetServiceCalls, payload:{state: []} });
        return;
    }


    return <AppContext.Provider value={{state, dispatch, GetCallInfo,Logout}}>
        {children}
    </AppContext.Provider>
}