import React, {useState} from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './style.scss';

export const Card = ({header = [], body = [],call, id, button = null}) => {

    const [isOpen, setIsOpen] = useState(false)

    const classes = classNames('Card' , {open: isOpen})
    return (
        <div className={classes}>
            <div className='Card__header' onClick={() => setIsOpen(!isOpen)}>
                {header.map(item => 
                        <div key={item.key} className='header_item'>
                            <div className='header_key'>
                                {item.key}
                            </div>
                            <div className='header_info'>
                                {item.value}
                            </div>
                        </div>
                )}
          

            <div className='icon menu'/>
            </div>
            

        

            <div className='Card__body'>
            {body.map(item => 
                        <div key={item.key} className='body_item'>
                            <div className='body_key'>
                                {item.key}
                            </div>
                            <div className='body_info'>
                                {item.value}
                            </div>
                        </div>
                )}

                {button ? button : 
                <button className='button'>
                <Link to={{pathname: `/call/${id}`, state: call}}>פרטים נוספים</Link>
                </button> 
                }
            </div>
        </div>
    )
}


Card.propTypes = {
    header: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string | PropTypes.element
    })),
    body: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string | PropTypes.element
    })),
    id: PropTypes.string,
    call: PropTypes.object,
    button: PropTypes.element
}