import React ,{useState, useContext, useRef} from 'react'
import Http from 'service/http';
import {Link, useHistory} from 'react-router-dom'
import { AppContext } from 'context';
import {Actions} from 'context/reducer/types';
import {AsyncButton} from 'components/AsyncButton';
import { Error } from 'components/Error';
import ReCAPTCHA from "react-google-recaptcha";
import './style.scss';




const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$/;
export const Login = () => {

    const {dispatch} = useContext(AppContext); 
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);

    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [ShowError, setShowError] = useState(false);
    const [failedLogin, setFailedLogin] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const buttonRef = useRef();
    const recaptchaRef = useRef();

    const handleLogin = async() => {
        try {
            setShowError(false);
            setFailedLogin(false);
            setInvalidEmail(false);


            if(!Email || !Password || !emailRegex.test(Email)){

                if(!emailRegex.test(Email)) setInvalidEmail(true)
                setShowError(true);
                return;
            }
            const captchaHash = await recaptchaRef.current.executeAsync();
            const token = await Http.Login({Email, Password, captchaHash});
            sessionStorage.setItem('sessionId', token.authorization);
            const userData = await Http.GetCustName();
            dispatch({type: Actions.SetUser, payload:{state: {userName: token.NAME, userData}}})
            history.replace('/home');
        } catch (error) {
            if(error === 403 || error === 401){
                setFailedLogin(error)
            }
            else{
                setFailedLogin(true);
            }
            await recaptchaRef.current.reset();

        }
        
    }

    const handleEnter = (e) => {
        if(e.keyCode === 13 && buttonRef.current  ){
            buttonRef.current.Click();
        } 
    }

    return (
        <div className='Login'>
            <div className='Login_container'>
                <div className='title'>שלום</div>
                <div className='Login_subtitle'>פורטל קריאות אי.די.פי - כניסה למערכת</div>

                <div className='input'>
                    <label htmlFor='email'>כתובת דוא"ל</label>
                    <input id='email' type='email' value={Email} onChange={(e)=>setEmail(e.target.value)} onKeyUp={handleEnter}></input>
                    <Error show={!Email && ShowError}>שדה זה חובה</Error>
                    <Error show={invalidEmail && ShowError}>כתובת דוא"ל אינה חוקית</Error>
                </div>
                
                <div className='input'>
                    <label htmlFor='password'>סיסמה</label>

                    <div className='input_password'>
                        <div className={`eye ${showPassword ? 'shown' : 'hidden'}`} onClick={() => setShowPassword(!showPassword)}></div>
                        <input id='password' type={showPassword ? 'text' : 'password'} value={Password} onChange={(e)=>setPassword(e.target.value)} onKeyUp={handleEnter}/>
                    </div>
                    <Error show={!Password && ShowError}>שדה זה חובה</Error>

                </div>
                <Error className='loginError' show={failedLogin === 401}>הדוא"ל או הסיסמה שגויים</Error>
                <Error className='loginError' show={failedLogin === 403}>חשבונך נחסם עקב מספר ניסיונות כניסה כושלים. יש לנסות שוב מאוחר יותר</Error>
                <Error className='loginError' show={failedLogin === true}>שגיאת מערכת, יש לנסות שוב מאוחר יותר</Error>

                <AsyncButton ref={buttonRef} onClick={handleLogin}>כניסה</AsyncButton>
                <Link className='forgot' to='/forgotpassword'>שכחתי סיסמה/הנפקת סיסמה ראשונית</Link>

            </div>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                size={'invisible'}
            />
        </div>
    )
}
