import React, { useState,forwardRef, useImperativeHandle }  from "react";
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router-dom';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import './style.scss';



const maxItemsOptions = [6,10,20,50];
export const Table = forwardRef((props, ref) => {

    const {calls, onReachedEnd,loading = false} = props;
    const [maxItems, setMaxItems] = useState(6);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
        const lastPage = Math.ceil(calls.length / maxItems);
        if(lastPage <= data.selected + 1) onReachedEnd();
      }



       useImperativeHandle(ref, () => ({

        ResetPage() {
          setCurrentPage(0);
        }

      }));



      
  const exportToCSV = () => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    const ws = XLSX.utils.aoa_to_sheet ([
        [ "מספר קריאה",    "נושא", "נפתח על ידי" , "תאריך פתיחה" , "שם הלקוח" , "סוג קריאה" , "סטטוס", "לטיפול" ],
        ...calls.map(call => [call.DOCNO, call.EDPJ_QTEXT, call.NAME, moment(call.STATUSDATE).format('DD.MM.YYYY'), call.CDES, call.ECODE, call.CALLSTATUSCODE, call.TECHNICIANLOGIN])
      ]
    );
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, `table_export${moment().format('DD.MM.YYYY')}${fileExtension}`);
}


  const startIndex = currentPage * maxItems;
  return (
    <div className="Table">
      <div className='table_container'>
      <table className="table">
        <thead>
          <tr>
            <th className="table_header">
              מספר קריאה
            </th>
            <th className="table_header">
              נושא 
            </th>
            <th className="table_header">
              נפתח על ידי
            </th>
            <th className="table_header">
              תאריך פתיחה 
            </th>
            <th className="table_header">
            שם הלקוח 
            </th>
            <th className="table_header">
              סוג קריאה 
            </th>
            <th className="table_header">
              סטטוס 
            </th>
          </tr>
        </thead>
        <tbody>
          {calls.slice(startIndex, startIndex + maxItems).map((call) => (
            <tr key={call.DOCNO}>
              <td className="table_item link"><Link to={{pathname: `/call/${call.DOCNO}`, state: call}}>{call.DOCNO}</Link></td>
              <td className="table_item">{call.EDPJ_QTEXT}</td>
              <td className="table_item">{call.NAME}</td>
              <td className="table_item">{moment(call.STATUSDATE).format('DD.MM.YYYY')}</td>
              <td className="table_item">{call.CDES}</td>
              <td className="table_item">{call.ECODE}</td>
              <td className="table_item">{call.CALLSTATUSCODE}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      <div className="paginate_container">
      <select onChange={(e) => {setMaxItems(parseInt(e.target.value))}}>
            {maxItemsOptions.map(option => <option className='pageViewSelectors_item' key={option} value={option}>{option}</option>)}
        </select>
        {loading && <div className='loader'></div>}
        <ReactPaginate
          previousLabel={">>"}
          nextLabel={"<<"}
          breakLabel={""}
          breakClassName={"break-me"}
          pageCount={Math.ceil(calls.length / maxItems)}
          marginPagesDisplayed={0}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPage}
        />

        <button className="button btn_secondary excel_export" onClick={exportToCSV}>יצוא לאקסל</button>
      </div>
    </div>
  );
});
