import { useRef } from 'react';
import {useState, useEffect} from 'react'
export const MOBILE_SIZE = parseInt(process.env.REACT_APP_MOBILE_SIZE);
export const TABLET_SIZE = parseInt(process.env.REACT_APP_TABLET_SIZE);

export function useScreenSize() {
    const isClient = typeof window === 'object';
  
    function getSize() {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      };
    }
  
    const [windowSize, setWindowSize] = useState(getSize);
  
    useEffect(() => {
      if (!isClient) {
        return false;
      }
      
      function handleResize() {
        setWindowSize(getSize());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
       // eslint-disable-next-line
    }, []); // Empty array ensures that effect is only run on mount and unmount
  
    return windowSize;
  }



  export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }