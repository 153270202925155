import React, {useState, useContext, useEffect, useCallback, useRef} from "react";
import { Popup } from "components/Popup";
import { NewCall } from "components/NewCall";
import { Table } from "components/Table";
import {AppContext} from 'context';
import {Actions} from 'context/reducer/types';
import http from 'service/http';
import classNames from 'classnames';
import {useScreenSize, TABLET_SIZE} from 'service/utils';
import { Card } from "components/Card";
import moment from 'moment';
import { Link } from "react-router-dom";
import { AsyncButton } from "components/AsyncButton";
import { SingleDatePicker } from 'react-dates';

import 'react-dates/initialize';
import "./style.scss";


export const Home = () => {


  const {state , dispatch} = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const DOCNO = useRef(undefined);
  const EDPJ_QTEXT = useRef(undefined);
  const NAME = useRef(undefined);
  const CUSTNAME = useRef(undefined);
  const CALLTYPECODE = useRef(undefined);
  const buttonRef = useRef();
  const screen = useScreenSize();
  const [searchDate, onSearchDate] = useState(null);
  const [focus,setFocus] = useState(false);
  const [searched,setSearched] = useState(false);
  const [fetching,setFetching] = useState(false);

  const TableRef = useRef(undefined)



  const PopupChange = (newState) => {
    setIsOpen(newState);
  }


  const handleEnter = (e) => {
    if(e.keyCode === 13 && buttonRef.current  ){
        buttonRef.current.Click();
    } 
}


  const GetServices = useCallback(async() => {
    if(isLoading) return;
   
    try {
      setIsLoading(true);
      const calls = await http.GetAllServiceCalls();
      dispatch({type: Actions.SetServiceCalls, payload: {state: calls} }) 
      setSearched(false);
    } catch (error) {
      console.error(error)
    }
    finally{
      setIsLoading(false);
    }

    
    // eslint-disable-next-line
    },[dispatch])




  useEffect(()=> {GetServices()},[GetServices])



  const FetchCalls = async() =>{
   
    if(fetching) return;
    try {
      setFetching(true);
      const lastServiceCall = state.ServiceCalls[state.ServiceCalls.length - 1]; // let last array Element
      const calls = await http.GetAllServiceCalls(lastServiceCall.STARTDATE);
      dispatch({type: Actions.AddServiceCalls , payload: {state: calls}})
    } catch (error) {
      
    }
    finally{
      setFetching(false)
    }
  }


  const SearchServiceCalls = async() => {
      try {
        const DOCNO_value = DOCNO.current.value;
        const EDPJ_QTEXT_value = EDPJ_QTEXT.current.value;
        const NAME_value = NAME.current.value;
        const {CUSTNAME: CUSTNAME_value} = state.User?.userData[CUSTNAME.current.selectedIndex - 1] || '';
        const {CODE: CALLTYPECODE_value} = state.Types[CALLTYPECODE.current.selectedIndex - 1]  || '';
        const STARTDATE = searchDate?.format('DD/MM/YYYY') || ''
        setSearched(true);
        const calls = await http.SearchServiceCalls({DOCNO: DOCNO_value, EDPJ_QTEXT: EDPJ_QTEXT_value , STARTDATE, NAME: NAME_value, CUSTNAME: CUSTNAME_value , CALLTYPECODE: CALLTYPECODE_value});
        dispatch({type: Actions.SetServiceCalls, payload: {state: calls} }) 
        setIsFilterOpen(false);
        TableRef.current.ResetPage();
        return true;
      } catch (error) {
        
      }
  }


  const onLastCall = async() => {
    if(!searched) return await FetchCalls();
  }



  const refreshing = classNames('button btn_refresh', {animating: isLoading})
  return (
    <div className="Home">
      <div className="Home__title_container">
        <div className="container">
          <div className="title"> <button className={refreshing} onClick={GetServices}></button> <span className='pipe'></span> רשימת קריאות שירות</div>
          <div className='buttons'> 
            <button className="button btn_round" onClick={()=>setIsOpen(true)}>{screen?.width > TABLET_SIZE ? 'פתיחת קריאה חדשה' : '+'}</button>
          </div>
        </div>
      </div>

      <div className="Home__table_container">
        
        <div className="container searchContainer">
          <button className='button btn_large' onClick={()=>setIsFilterOpen(true)}>חיפוש</button>
          {searched&&<button className='button btn_large' onClick={()=>GetServices()}>נקה חיפוש</button>}
          {state.ServiceCalls.length === 0 && !isLoading ?  <div className='noresults'>אין תוצאות</div>
           : isLoading ? <div className='loader'></div> :
            screen?.width > TABLET_SIZE ? 
            <Table ref={TableRef} calls={state.ServiceCalls} onReachedEnd={onLastCall} loading={fetching}/>
            : 
            <div className='Mobile_Cards'>
              {state.ServiceCalls.map(call => 
              <Card key ={call.DOCNO}
                call = {call}
                id= {call.DOCNO}
                header={[{key: 'מספר קריאה', value:<Link to={{pathname: `/call/${call.DOCNO}`, state: call}}>{call.DOCNO}</Link>}, {key: 'תאריך פתיחה', value: moment(call.STATUSDATE).format('DD.MM.YYYY')}]} 
                body={[
                  {key: 'נושא', value: call.EDPJ_QTEXT},
                  {key: 'נפתח על ידי', value: call.NAME},
                  {key: 'שם הלקוח', value: call.CDES},
                  {key: 'סוג קריאה',value: call.ECODE},
                  {key: 'סטטוס', value: call.CALLSTATUSCODE}
                ]}
              /> )}
              {!searched&&<AsyncButton className='loadmore btn_large' onClick={onLastCall}>טען עוד</AsyncButton>}
            </div>
            
          }
         
        </div>
      </div>

      <Popup isOpen={isOpen} onChange={PopupChange} title ='הצלחה!' subtitle ='קריאת השירות נוצרה בהצלחה'>
        <NewCall />
      </Popup>


      <Popup className='search' isOpen={isFilterOpen} onChange={(newState) =>setIsFilterOpen(newState)}>
        <div className='Filters'>
        <div className='title'>חיפוש</div>
          <div className='Filters_items'>
            <div className="inputs_item">
                <input placeholder='מספר קריאה' ref={DOCNO} onKeyUp={handleEnter}></input>
            </div>

            <div className="inputs_item">
                <input placeholder='נושא' ref={EDPJ_QTEXT} onKeyUp={handleEnter}></input>
            </div>

            <div className="inputs_item">
                <input placeholder='נפתח על ידי' ref={NAME} onKeyUp={handleEnter}></input>
            </div>

            <div className='date_picker'>
              <SingleDatePicker 
                 date={searchDate} // momentPropTypes.momentObj or null
                 onDateChange={date => onSearchDate(date)} // PropTypes.func.isRequired
                 focused={focus} // PropTypes.bool
                 onFocusChange={({ focused }) => setFocus(focused)} // PropTypes.func.isRequired
                 id="your_unique_id" // PropTypes.string.isRequired,\
                 numberOfMonths={1}
                 displayFormat='DD/MM/YYYY'
                 placeholder='תאריך'
                 isRTL={true}
                 isOutsideRange={()=>false}
              />
            </div>

            <div className="inputs_item">
                <select ref={CUSTNAME}>
                  <option value={null}>שם הלקוח</option>
                  {state.User?.userData.map(detail => <option key={detail.CUSTNAME} value={detail.CUSTNAME}>{detail.CUSTDES}</option>)}
                </select>
            </div>

            <div className="inputs_item">
                <select ref={CALLTYPECODE}>
                <option value={null}>סוג קריאה</option>
                  {state.Types.map(type => <option key={type.CALLTYPE} value={type.CALLTYPE}>{type.ECODE}</option>)}
                </select>
              </div>

          </div>
            <AsyncButton ref={buttonRef} className='btn_large btn_search' onClick={SearchServiceCalls}>חפש</AsyncButton>

        </div>
      </Popup>
    </div>
  );
};
