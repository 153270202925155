import React from "react";
import './style.scss';

export const Post = ({info, title}) => {
  return (
    <div className="Post">
        <div className="Post_title">{title}</div>
        <div className="Post_info" dangerouslySetInnerHTML={{__html: info}}></div>
    </div>
  );
};
