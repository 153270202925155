import React, {useContext, useRef, useState, useEffect } from "react";
import {TextEditor} from 'components/TextEditor';
import { AppContext } from "context";
import {Actions} from 'context/reducer/types';
import { AsyncButton } from "components/AsyncButton";
import Http from "service/http";
import { Error } from "components/Error";

import "./style.scss";



export const NewCall = ({ Finish }) => {

  const {state, dispatch} = useContext(AppContext);
  const [text, setText] = useState("")
  const [files, setFiles] = useState([])
  const [types, setTypes] = useState([])
  const CUSTREF = useRef(undefined);
  const TOPICREF = useRef(undefined);
  const TYPEREF = useRef(undefined);
  const [failedNewPost, setfailedNewPost] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const Save = async() =>{
    try {
      setSubmitted(true);
      if(!TOPICREF.current.value || !text) return;
      setSubmitted(false);

      const {CUSTNAME, NAME} = state.User?.userData[CUSTREF.current.selectedIndex];
      const {CODE: CALLTYPECODE} = state.Types[TYPEREF.current.selectedIndex];
      setfailedNewPost(false);
      const call = await Http.InserNewServiceCall(
        {
          CUSTNAME,
          CALLTYPECODE,
          NAME,
          EDPJ_QTEXT: TOPICREF.current.value
        })
      await Http.UpdateText({id: call.DOCNO, text})
      if(files.length !== 0){
        await Http.UpdatFile({id: call.DOCNO, files});
      }
      dispatch({type: Actions.AddServiceCall, payload: {state: call}})
      setText('')
      Finish();

    } catch (error) {
      console.error(error);
      setfailedNewPost(true);
    }
 
   
   
  }
  
  const handleFiles = (files) => {
    setFiles(files);
  }

  const handleText = (text) => {
    setText(text);
  }



  const GetTypes = async() => {
    try {
      const {CUSTNAME} = state.User?.userData[CUSTREF.current.selectedIndex];
      if(CUSTNAME){
        const types_data = await Http.GetAllCallTypesById(CUSTNAME);
        setTypes(types_data);
      }
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    GetTypes()

    Array.from(document.querySelectorAll('.ql-formats button')).forEach(item=>item.setAttribute('tabindex', 1))
    Array.from(document.querySelectorAll('.ql-picker-label')).forEach(item=>item.setAttribute('tabindex', 1))
    document.querySelector('.ql-picker-label').setAttribute('tabindex', 1)
    document.querySelector('pre.ql-container').setAttribute('tabindex', -1)

  }, []);
 

      return (
        <div className="NewCall">
          <div className="NewCall_title">קריאות שירות חדשה</div>
          <div className="NewCall_inputs">
            <div className="inputs_item">
              <div className="placeholder">לקוח</div>
              <select ref={CUSTREF} onChange={GetTypes}>
                {state.User?.userData.map(detail => <option key={detail.CUSTNAME} value={detail.CUSTNAME}>{detail.CUSTDES}</option>)}
              </select>
            </div>
            <div className="inputs_item">
              <div className="placeholder">נושא</div>
              <input type='text' ref={TOPICREF} tabIndex={0} autoFocus></input>
              <Error show={submitted && !TOPICREF.current.value}>שדה זה חובה</Error>
            </div>
            <div className="inputs_item">
              <div className="placeholder">סוג קריאה</div>
              <select ref={TYPEREF} tabIndex={0}>
                {types.map(type => <option key={type.CALLTYPE} value={type.CALLTYPE}>{type.ECODE}</option>)}
              </select>
            </div>
          </div>
            <TextEditor subtitle='פרטים' onFileChange={handleFiles} text={text} onTextChange={handleText} fileExt={state?.WhitelistFiles || []}/>
            <Error show={submitted && !text}>שדה זה חובה</Error>

          <AsyncButton className="btn_secondary" onClick={Save}>שליחה</AsyncButton>
          <Error className='loginError' show={failedNewPost}>שגיאת מערכת אנא נסה שוב</Error>

        </div>
      );

};
