import { Error } from 'components/Error';
import React, {useRef, useState, useEffect} from 'react'
import ReactQuill, {Quill} from "react-quill";
import { usePrevious } from 'service/utils';


import './style.scss';

const DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);
const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);
const BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);
const ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);
const FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);
const SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);
const SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);



export const TextEditor = ({tabIndex = 0,subtitle = '' ,title = '',text = '', onTextChange,files, onFileChange, fileExt = []}) => {

  const uploaderRef = useRef(undefined);
  const QuilRef = useRef(undefined);
  const [_files, setFiles] = useState(files || []);
  const [isValidFile, setIsValidFile] = useState(true);

  const handleTextChange = (value) => {
    const valueHTML = document.createElement('div');
    valueHTML.innerHTML = value
    const filteredValue = valueHTML.innerText ? value : '';
    onTextChange(filteredValue);
   
  }


  const fileChanged = (e) => {

    const valid = Array.from(e.target.files).some(file => fileExt.some(ext=> file.name.toLowerCase().endsWith(ext.toLowerCase())));
    //file.type.includes(ext.replace('.', '')) if need to check types too please ask for types array to be in fileExt
     
      setIsValidFile(valid);

      if(!valid) return;

    if(files) setFiles([...files, ...Array.from(e.target.files)]);
    else      setFiles([..._files, ...Array.from(e.target.files)]);

  }

  const handleFileRemove = (index) => {
    setFiles(_files.filter( (_,i) => i !== index));
  }

  useEffect(() => {
    onFileChange(_files);
      // eslint-disable-next-line
  }, [_files]) 


  const loaded = useRef(false);
  useEffect(() => {
    FixRTL();
  },[text])

  useEffect(() => {
    loaded.current = true;

    document.querySelector('.ql-editor').addEventListener('paste' , e => {
      const image = e.clipboardData.items[0];
      if(image.type.includes('image') && image.kind === 'file'){
        e.preventDefault();
        const file = image.getAsFile();
        if(files) setFiles([...files, file]);
        else      setFiles([..._files, file]);
      }
    })
  }, [])

const FixRTL = () => {
  if(loaded.current&&!text && QuilRef.current) {
    const editor = QuilRef.current.editor
    editor.setText('');
    editor.format('direction', 'rtl');
    editor.format('align', 'right');
  }
}



const fileExts = fileExt.join();
    return (
        <div className='TextEditor'>
          <div className='TextEditor_title'>
            {title}
          </div>
            <div className='TextEditor_subtitle'>
              <div className="quill_title">{subtitle}</div>
              <label className='button' htmlFor='fileUpload'>
                הוספת קובץ
              </label>
              <input
                  id='fileUpload'
                  ref={uploaderRef}
                  type="file"
                  onChange={fileChanged}
                  accept={fileExts}
                  multiple
                />
            </div>
            <ReactQuill
              theme={"snow"}
              modules={TextEditor.modules}
              formats={TextEditor.formats}
              onChange={handleTextChange}
              bounds={".popup"}
              ref={QuilRef}
              preserveWhitespace={true}
              tabIndex={tabIndex}
              onFocus={FixRTL}
            />
           
            

            <div className='TextEditor_files'>
              {(files || _files).map((file, i) => 
              <div key={i} className='file_item button'>
                {file.name}
                <div className='close' onClick={()=> handleFileRemove(i)}>X</div>
              </div>)}
            </div>
            <Error show={!isValidFile}>סוג קובץ זה לא נתמך</Error>
            
        </div>
    )
}


TextEditor.modules = {
    toolbar: [
      [{ font: [] }],
      [{ direction: "rtl" }],
      [{ align: [] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  TextEditor.formats = [
    "font",
    "direction",
    "align",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
  ];
  