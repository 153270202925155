

export const Actions = {
    SetUser: 'setuser',
    SetTopics: 'settopics',
    SetTypes: 'settypes',
    SetWhitelistFiles: 'setwhitelistfiles',
    SetServiceCalls: 'setservicecalls',
    AddServiceCalls: 'addservicecalls',
    SetCallInfo: 'setcallinfo',
    AddServiceCall: 'addservicecall'

}