const isDebug = new URLSearchParams(window.location.search).get('debug');
const API = isDebug === 'true' ? 'http://localhost' : process.env.REACT_APP_API_URL;

const headers = new Headers();
headers.append('Authorization' , '');
headers.append('Access-Control-Allow-Origin','*');
headers.append('Content-Type','application/json');


const mocking = process.env.REACT_APP_MOCK_DATA;

export default class Http {

    

    static async Fetch(EndPoint, Options = {}, includeHeaders = true){
        if(EndPoint){
            try {
                if(mocking === 'true'){
                    try {
                        const path = EndPoint.split('?')[0]; // remove url params
                        const mocking = (await import(`./mock/${path}.json`)).default;
                        var blob = new Blob([JSON.stringify(mocking)], {type : 'application/json'});
                        return new Response(blob);
                    } catch (error) {
                        console.warn(`fetch: mocking data on ${EndPoint}` , error);
                    }
                }

                const sessionId = sessionStorage.getItem('sessionId');
                if(sessionId && !headers.get('Authorization')) headers.set('Authorization', sessionId);
                let options = {...Options};
                if(includeHeaders) options = {...options , headers};
                const res = await fetch(`${API}/${EndPoint}`, {...options})
                if(res.ok) return res;
                else throw res.status
                
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
    }

    static async Login({Email, Password, captchaHash}){
        
        try {
            const res =  await this.Fetch(`Login?username=${Email}&password=${Password}&captchaResponse=${captchaHash}` , { method: 'GET' });
            if(res.status !== 200){
                throw res.status;
            }
            const token = await res.json();
            headers.set('Authorization' ,token.authorization);
            return token;
        } catch (error) {
            throw error;
        }
    }


    static async GetAllServiceCalls(startdate, top = 101){
        try {
            const res =  await this.Fetch(`GetAllServiceCalls?top=${top}&statusdate=${startdate || ''}` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    static async UpdateText({id, text}){
        try {
            const now = new Date();
            const _text = `${now.toLocaleString('he-IL', { hour12: false})}<br/><br/>${text}`
            const res =  await this.Fetch(`UpdateText` , { method: 'POST', body: JSON.stringify({id, text: _text}) });
            const data = await res.json();
            if(data) return data;
            throw data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    static async UpdatFile({id, files}){
        try {

            const formData = new FormData();
            files.forEach((file, i) => {
                formData.append(`file${i}`, file);
            });
         
            //const res =  await this.Fetch(`UpdatFile` , { method: 'POST', body: formData });
            const res =  await fetch(`${API}/UpdatFile?id=${id}`, {
                method: 'POST',
                body: formData,
                headers:{
                    "Authorization": headers.get('Authorization')
                }
            })
            const data = await res.json();
            if(data) return data;
            throw data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    
    static async InserNewServiceCall({CUSTNAME, EDPJ_QTEXT = "", CALLTYPECODE,NAME,text}){
        try {
       
            const res =  await this.Fetch(`InserNewServiceCall` , 
            { method: 'POST', 
            body: JSON.stringify(
                {
                    CUSTNAME,
                    EDPJ_QTEXT,
                    NAME,
                    CALLTYPECODE,
                    text
                }
                )});
            const data = await res.json();
            if(data) return data;
            throw data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    
    

    static async GetCustName(){
        try {
            const res =  await this.Fetch(`GetCustName` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    
    static async GetCallInfoById(id){
        try {
            const res =  await this.Fetch(`GetCallInfoById?id=${id}` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async GetTextById(id){
        try {
            const res =  await this.Fetch(`GetTextsById?id=${id}` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async GetFilesById(id){
        try {
            const res =  await this.Fetch(`GetFilesById?id=${id}` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async GetAllTopics(){
        try {
            const res =  await this.Fetch(`GetAllTopics` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async GetUploadExtWhiteList(){
        try {
            const res =  await this.Fetch(`GetUploadExtWhiteList` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async GetAllCallTypes(){
        try {
            const res =  await this.Fetch(`GetAllCallTypesSearch` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    static async GetAllCallTypesById(id){
        try {
            const res =  await this.Fetch(`GetAllCallTypes?custname=${id}` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    
    static async ReOpenDocq(docno){
        try {
            const res =  await this.Fetch(`ReOpenDocq?DOCNO=${docno}` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    static async ForgotPassword(email, captchaHash){
        try {
            const res =  await this.Fetch(`ForgotPassword?email=${email}&captchaResponse=${captchaHash}` , { method: 'GET' });
            if(res.status !== 200){
                throw res.status;
            }
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async UpdatePassword({Email, Token , Password, captchaHash}){
        try {
            const res =  await this.Fetch(`updatefpservice?email=${Email}&token=${Token}&Password=${Password}&captchaResponse=${captchaHash}` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    static async SearchServiceCalls({DOCNO = '', EDPJ_QTEXT  = '' , NAME = ''  ,STARTDATE = '' , CUSTNAME  = '' , CALLTYPECODE  = ''} ){
        try {
            const res =  await this.Fetch(`SearchServiceCalls?DOCNO=${DOCNO}&EDPJ_QTEXT=${EDPJ_QTEXT}&NAME=${NAME}&STARTDATE=${STARTDATE}&CUSTNAME=${CUSTNAME}&CALLTYPECODE=${CALLTYPECODE}` , { method: 'GET' });
            return await res.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    

}