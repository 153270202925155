import React from 'react'
import './style.scss';


export const Footer = () => {
    return (
        <div className='Footer'>
            <a href='mailto:support@edp.co.il' target='_blank' rel='noopener noreferrer'>תקלה בפורטל? לחצו לתמיכה</a>
            <a href='https://www.edp.co.il/#contactus' target='_blank' rel='noopener noreferrer'>צור קשר</a>
            <a href='https://www.edp.co.il/privacy' target='_blank' rel='noopener noreferrer'>מדיניות פרטיות</a>
        </div>
    )
}
