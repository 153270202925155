import React , {useState, useRef, useEffect} from 'react'
import Http from 'service/http';
import {AsyncButton} from 'components/AsyncButton';
import { Error } from 'components/Error';
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useLocation } from 'react-router-dom';
import './style.scss';


const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$/;

export const ForgotPassword = () => {


    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [Token, setToken] = useState();
    const [ShowError, setShowError] = useState(false);
    const [Success, setSuccess] = useState(undefined);
    const [invalidEmail, setInvalidEmail] = useState(false);

    const buttonRef = useRef();
    const location = useLocation();
    const recaptchaRef = useRef();

    const handleEnter = (e) => {
        if(e.keyCode === 13 && buttonRef.current  ){
            buttonRef.current.Click();
        } 
    }



    useEffect(() => {
        const URL_params = new URLSearchParams(location.search);
        const email_param = URL_params.get('email');
        const token_param = URL_params.get('token');
        setToken(token_param);
        setEmail(email_param);
    }, [location.search])

    const handleForgotBtn = async() => {
        setShowError(false);
        setInvalidEmail(false);

        if(!Email || !emailRegex.test(Email)){

            if(!emailRegex.test(Email)) setInvalidEmail(true)
            setShowError(true);
            return;
        }


        try {
            const captchaHash = await recaptchaRef.current.executeAsync();
            await Http.ForgotPassword(Email,captchaHash);
            setSuccess(true);
        } catch (error) {
            if(error === 403 || error === 401){
                setSuccess(error)
            }
            else{
                setSuccess(false);
            }
            await recaptchaRef.current.reset();

        }
    }


    const handleChangePassword = async() => {
        setShowError(false);
        if(!Password) {
            setShowError(true);
            return;
        };
        try {
            const captchaHash = await recaptchaRef.current.executeAsync();
            const res = await Http.UpdatePassword({Email, Token, Password, captchaHash});
            setSuccess(res);
        } catch (error) {
            setSuccess(false);
            await recaptchaRef.current.reset();
        }
    }


    return (
        <div className='ForgotPassword'>
             <div className='ForgotPassword_container'>
                <div className='title'>שלום</div>
                {Token ? 
                <div className='ForgotPassword_subtitle'>פורטל קריאות אי.די.פי - שינוי סיסמה</div>
                : 
                <div className='ForgotPassword_subtitle'>פורטל קריאות אי.די.פי - שכחתי סיסמה</div>}

                <div className='input'>
                    <label htmlFor='email'>כתובת דוא"ל</label>
                    <input id='email' type='email' disabled={!!Token} value={Email} onChange={(e)=> setEmail(e.target.value)} onKeyUp={handleEnter}></input>
                    <Error show={!Email && ShowError}>שדה זה חובה</Error>
                    <Error show={invalidEmail && ShowError}>כתובת דוא"ל אינה חוקית</Error>
                </div>

                {Token ? 
                 <div className='input'>
                    <label htmlFor='email'>סיסמה חדשה</label>
                    <input id='password' type='password' value={Password} onChange={(e)=> setPassword(e.target.value)} onKeyUp={handleEnter}></input>
                    <Error show={!Password && ShowError}>שדה זה חובה</Error>
                </div>
                : null}
               


                {Success === true && (Token ? <div className='ForgotPassword_Success'>סיסמה שונתה בהצלחה</div> : <div className='ForgotPassword_Success'>דוא"ל שינוי סיסמה נשלח</div>)  }
                <Error show={Success===false && Token}>שינוי סיסמה פג תוקף, אנא נסה שנית</Error>
                <Error show={Success===403}>לא ניתן לאפס סיסמה. יש לנסות שוב מאוחר יותר</Error>
               
                    {Success !== true && <AsyncButton ref={buttonRef} onClick={Token ?  handleChangePassword : handleForgotBtn}>שלח</AsyncButton>}
                
                
                <Link className='login' to='/'>כניסה</Link>

              
            </div>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                size={'invisible'}
            />
        </div>
    )
}
