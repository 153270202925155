import React, {useEffect, useContext, useState, useRef, useCallback} from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "components/Tabs";
import { Post } from "components/Post";
import { TextEditor } from "components/TextEditor";
import {AppContext} from 'context';
import { Popup } from "components/Popup";
import moment from 'moment';
import Http from "service/http";
import { AsyncButton } from "components/AsyncButton";
import classNames from 'classnames';
import { Actions } from "context/reducer/types";
import {useScreenSize, TABLET_SIZE} from 'service/utils';
import { Card } from "components/Card";
import { Error } from "components/Error";

import "./style.scss";



export const Call = () => {
  const { id } = useParams();
  const {state, GetCallInfo, dispatch} = useContext(AppContext);
  const [CallInfo, setCallInfo] = useState(null);
  const [isOpen, setisOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [comment, setComment] = useState("");
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [failedPost, setfailedPost] = useState(false);
  const screen = useScreenSize();




  const GetInfo = useCallback(async (callInfo) => {

    try {
        setIsLoading(true);
        const allFiles = await Http.GetFilesById(id);
        const allTexts = await Http.GetTextById(id);
        if(!unmounted.current) {
          dispatch({type: Actions.SetCallInfo, payload:{id, state: {...callInfo, CallInfo: 
            {...callInfo.CallInfo, allFiles, allTexts}}}});
            setIsLoading(false);
        }

      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }

      
    // eslint-disable-next-line
    },[id, dispatch])

  const unmounted = useRef(false);
  useEffect(() => {
    if(!isLoading){
      setIsLoading(true);
      GetCallInfo(id)
        .then(callInfo => {
          GetInfo(callInfo);
        })
        .catch(err=>console.error(err))
    }
    
  

    return () => {
      unmounted.current = true;
    }

    // eslint-disable-next-line
  }, [id, GetInfo])

  useEffect(() => {
    const foundCall = state.ServiceCalls.find(call => call.DOCNO === id);
    if(foundCall) setCallInfo(foundCall);

      
  }, [state.ServiceCalls, id])


  useEffect(()=> {
    const _posts = CallInfo?.CallInfo?.allTexts?.TEXT.split(/<hr\s*\/?>|<hr\s*ss=""\s*\/?>/gi).filter(str => {
      const ele = document.createElement('div');
      ele.innerHTML = str
      return !!ele.innerText.trim(); // remove empty posts
    }).map(post => {
      const ele = document.createElement('div');
      ele.innerHTML = post.replace(/<style>.*<\/style>/g, '') // removing style tags
      const childs = ele.querySelectorAll(":scope > *");
      
      let isCustomerPost = false;
      for (const child of childs) {
        if(!child.innerText.trim()){
          ele.removeChild(child);
        }
        if(child.innerText === '[WEB_CLIENT]'){
          ele.removeChild(child);
          isCustomerPost = true;
        }
      }

      if(isCustomerPost) ele.innerHTML = `[WEB_CLIENT] ${ele.innerHTML}`;
      
      return ele.innerHTML;
    });
    setPosts(_posts);
  }, [CallInfo])


  const handleFiles = (files) => {
    setFiles(files);
  }

  const handleText = (text) => {
    setComment(`${text}`);
  }

  const handlePost = async () => {
    try {
      if(!comment && files.length === 0) return;
      setfailedPost(false);

      let updateText_res, updateFile_res;
      if(comment) updateText_res = await Http.UpdateText({id, text: comment});
      if(files.length !== 0) {
        updateFile_res = await Http.UpdatFile({id, files});
      }
      dispatch({type: Actions.SetCallInfo, payload:{id, state: {...CallInfo, CallInfo: 
        {...CallInfo.CallInfo,
          allFiles: updateFile_res || CallInfo.CallInfo.allFiles,
          allTexts: updateText_res || CallInfo.CallInfo.allTexts 
        }}}})

      setisOpen(true);
      handleFiles([]);
      handleText('');
    } catch (error) {
      setfailedPost(true);
    } 
  }

  const handlePopup = (popupState) => {
    setisOpen(popupState);
  }


  const onReOpenClick = async() => {
    try {
      setIsLoading(true);
      const call = await Http.ReOpenDocq(id);
      if(call){
        dispatch({type: Actions.SetCallInfo, payload:{id, state: {...call, CallInfo: 
          {...CallInfo.CallInfo}}}})
      }
    } catch (error) {
      
    }
    finally{
      setIsLoading(false);
    }
  }

  const refreshing = classNames('button btn_refresh', {animating: isLoading})
  return (
    <div className="Call">
      <Popup isOpen={isOpen} showSuccess={isOpen} onChange={handlePopup} title ='הצלחה!' subtitle ='קריאת השירות עודכנה בהצלחה' />
      <div className="Call_info_container">
        <div className="container">
          <div className="Call_title">
            מספר קריאה: <b>{id}</b>
          </div>

          <div className="Call_info">
            <div className="item title">סטטוס</div>
            <div className="item">{CallInfo?.CALLSTATUSCODE}</div>

            <div className="item title">נושא</div>
            <div className="item">{CallInfo?.EDPJ_QTEXT}</div>

            <div className="item title">שם הלקוח</div>
            <div className="item">{CallInfo?.CDES}</div>

            <div className="item title">נפתח על ידי</div>
            <div className="item">{CallInfo?.NAME}</div>

            <div className="item title">תאריך פתיחת קריאה</div>
            <div className="item">{moment(CallInfo?.STATUSDATE).format('DD.MM.YYYY')}</div>

            <div className="item title">סוג קריאה</div>
            <div className="item">{CallInfo?.ECODE}</div>

          </div>
        </div>
      </div>

      {
        CallInfo ? CallInfo.EDPYDISABLED ? 
        <div className='closedCallContainer'>
        <Error className='closedCallError' show={true} >קריאה זו סגורה</Error>
        <AsyncButton className='btn_large' onClick={onReOpenClick}>לפתיחה מחדש לחצו כאן</AsyncButton>
        </div>
        : 
        <div className="Call_post container">
        <TextEditor files={files} text={comment} title="הוסף תגובה חדשה:" onFileChange={handleFiles} onTextChange={handleText} fileExt={state?.WhitelistFiles || []}/>
        <AsyncButton onClick={handlePost} className="btn_secondary">שליחת תגובה / קבצים</AsyncButton>
        <Error className='loginError' show={failedPost}>שגיאת מערכת אנא נסה שוב</Error>
      </div> : null
      }
  

      <div className="Call_posts">
        <div className="container">
        <button className={refreshing} onClick={()=>GetInfo(CallInfo)}></button>
          <Tabs
            tabs={[
              {
                title: "יומן אירועים",
                render: () => (
                  <div className="Posts">
                    <div className="subtitle">היסטוריית התכתבויות:</div>
                    {isLoading && !posts &&<div className='loader'></div>}
                    {posts?.map((post, index) => 
                    <Post
                      key={index}
                      title={post.includes('[WEB_CLIENT]') ? "לקוח" : "צוות EDP"}
                      info={post.replace(/\[WEB_CLIENT\]/g, "")}
                    />)}
                    
                  </div>
                ),
              },
              {
                title: `מסמכים (${CallInfo?.CallInfo?.allFiles.value?.length || 0})`,
                render: () => (
                  <div className="Documents">
                    {isLoading && !CallInfo?.CallInfo?.allFiles  ? <div className='loader'></div>:
                    screen?.width > TABLET_SIZE ? 
                    <table>
                    <thead>
                      <tr>
                        <th>שם הקובץ:</th>
                        <th>נוסף בתאריך:</th>
                      </tr>
                    </thead>
                      <tbody>{CallInfo?.CallInfo?.allFiles.value.map((doc, index) => <DownloadItem key={index} fileName={doc.EXTFILEDES} url={`${doc.FILEURL}${doc.EXTFILENAME.replace('../../system/mail', '')}`} date={ moment(doc.UDATE).format('DD.MM.YYYY')}/>)}</tbody>
                    </table>
                    :
                    CallInfo?.CallInfo?.allFiles.value.map((doc, index) => 
                    <Card key ={index}
                    header={[{key: 'שם הקובץ', value:doc.EXTFILEDES}]} 
                    body={[
                      {key: 'נוסף בתאריך', value: moment(doc.UDATE).format('DD.MM.YYYY')}
                    ]}
                    button={
                      <a target='_blank' rel="noopener noreferrer" download href={`${doc.FILEURL}${doc.EXTFILENAME.replace('../../system/mail', '')}`}>
                        <button className='button'>הורדת הקובץ</button>
                      </a>
                    }
                    />)
                  }
                    
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

const DownloadItem = ({ fileName, date, url }) => {



  return  (
    <tr>
      <td>{fileName}</td>
      <td>
        {date}
        <a target='_blank' rel="noopener noreferrer" download href={`${url}`}>
          <button className='button'>הורדת הקובץ</button>
        </a>
      </td>
    </tr>
  );
};
