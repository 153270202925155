import React, { useState, forwardRef, useImperativeHandle  } from 'react'
import classNames from 'classnames';
import './style.scss';

export const AsyncButton = forwardRef( (props, ref) => {

    const [loading, setLoading] = useState(false)
    const {children, onClick, className = ''} = props;


    useImperativeHandle(ref, () => ({
        Click(){
            handleAsyncClick();
        }
    }))

    const  handleAsyncClick = async () =>{
        if(loading) return;
        try {
            setLoading(true);
            await onClick();
        } catch (error) {
            
        }
        setLoading(false);
    }


    const classes = classNames(`button AsyncButton ${className}`,{loading})
    return (
        <button className={classes} onClick={handleAsyncClick}>{children}</button>
    )
})
