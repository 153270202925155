import React from 'react'
import classNames from 'classnames';
import './style.scss';



export const Error = ({className,show, children}) => {

    const classes = classNames('Error',className, {show})

    return (
        <div className={classes}>
            {children}
        </div>
    )
}
