import React, { useState, useEffect, useCallback } from "react";
import "./style.scss";

export const Popup = ({ children, isOpen, onChange = ()=>null, title, subtitle, showSuccess, className }) => {
  
  const [_showSuccess, setshowSuccess] = useState(false);

  const handleFinish = useCallback(() => {
    setshowSuccess(true);

    setTimeout(() => {
      onChange(false);
      setshowSuccess(false);
    }, 2000);

      // eslint-disable-next-line
  }, []);

  useEffect(() => {
    
    if(showSuccess === undefined) return;


    if(showSuccess === true ){
      handleFinish();
    }
  

  }, [showSuccess, handleFinish])

  if (!isOpen) return null;


      return (_showSuccess ? 
       <div className={`dropshadow ${className}`}>
          <div className="popup success">
            <div className="popup_successIcon"></div>
            <div className="popup_successTitle">{title}</div>
            <div className="popup_successSubtitle">{subtitle}</div>
          </div>
        </div>
        : 
        <div className={`dropshadow ${className}`}>
          <div className="popup">
            <div className="closeBtn" onClick={() => onChange(false)}></div>
            {children && React.cloneElement(children, { Finish: handleFinish })}
          </div>
        </div>
      );
        
  
};
