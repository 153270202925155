import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './style.scss';


export const Tabs = ({tabs = [{title: '', render: ()=> null}] }) => {

    const [tabIndex , setTabIndex] = useState(0);


    return (
        <div className='Tabs'>
            <div className='Tabs_tabs'>
                {tabs.map((tab, index) => <div key={index} onClick={() => setTabIndex(index)} className={`Tab ${tabIndex === index ? 'selected' : ''}`}>{tab.title}</div>)}             
            </div>
            {tabs[tabIndex].render()}
        </div>
    )
}


Tabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        render: PropTypes.func
    }))
}